<!-- 首页 -->
<template>
  <div class="container ignore">
    <div class="logo">
      <div class="back" @click="back()">
        <img src="@/assets/images/home/back@2x.png" alt />
      </div>
    </div>

    <div class="exam_box">
      <div class="exam_content">
        <div class="user_header margin-t-40">
          <div class="head_img">
            <img :src="liveForm.avatar" alt />
            <div class="edit_img">
              <div class="label"></div>
              <van-uploader
                accept="image/png, image/jpeg"
                image-fit="cover"
                v-model="fileList"
                :after-read="afterRead3"
                :before-read="beforeRead"
                upload-text="修改头像"
                :preview-image="showPreview3"
                :max-size="1024 * 1024 * 5"
                @oversize="onOversize"
                @delete="deleteBgUrl3"
              />
            </div>
          </div>
          <div class="user_info margin-t-20">
            <input
              ref="username"
              class="input_title"
              type="text"
              placeholder="请输入昵称,限制12个字"
              maxlength="12"
              v-model="liveForm.nickname"
            />
          </div>
        </div>
        <div class="btn_class margin-t-20 margin-b-40">
          <van-button plain type="primary" class="btn" @click="toSubmitExam"
            >保存信息</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateMyInfo } from "@/api/login";
import { postImgUpload } from "@/api/upload";
import { Toast, Dialog } from "vant";
import { setStore, getStore } from "@/utils/mUtils";

export default {
  components: {},
  data() {
    return {
      userInfo: {},
      file: null,
      file2: null,
      file3: null,
      politicsState: ["群众", "党员", "预备党员", "共青团员"],
      EduState: ["小学", "初中", "高中", "大专", "大学本科", "研究生及以上"],
      fileList: [],
      fileList2: [],
      fileList3: [],
      showPreview: false,
      showPreview2: false,
      showPreview3: true,
      showIdCardBoard: false,
      showPoliticsState: false,
      showEduState: false,
      showMarryState: false,
      liveForm: {
        nickname: "",
        avatar: "",
      },
      showArticleCats: false,
      articleCats: [],
      topBar: require("@/assets/images/home/top.png"),
      beforeExam: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    // 确认报名
    async toSubmitExam() {
      // 校验表单
      // 校验用户头像
      if (this.liveForm.avatar === "") {
        Toast("请上传头像");
        return false;
      }
      // 校验用户昵称
      if (
        this.liveForm.nickname === "" ||
        this.liveForm.nickname.replace(/(^\s*)|(\s*$)/g, "") === ""
      ) {
        Toast("请输入用户昵称");
        return false;
      }
      // 校验手机号
      // if (this.liveForm.phone === "") {
      //   Toast("请输入手机号");
      //   return false;
      // }
      console.log(this.liveForm);
      const res = await updateMyInfo(this.liveForm);
      console.log(res);
      if (res.msg === "SUCCESS") {
        // Toast('修改成功')
        this.$EventBus.$emit("getMyInfoFunc");
        this.$router.go(-1);
      } else {
      }
    },
    beforeRead(file) {
      // if (!this.checkImgAccept(file)) {
      //   return false;
      // }
      // this.fileList = [];
      // this.option.outputType = file.type.split("/")[1];
      this.showPreview = true;
      return true;
    },
    async afterRead3(file) {
      console.log(file);
      this.file3 = file;
      // this.$refs.cropper.loadFile(file.file);
      this.$showUpLoading();
      this.file3.status = "uploading";
      this.file3.message = "上传中...";
      const formData = new FormData();
      formData.append("file", file.file); // 要提交给后台的文件
      await postImgUpload(formData)
        .then((res) => {
          this.liveForm.avatar = res.data;
          this.file3.status = "done";
          console.log("完成");
          this.$hideLoading();
        })
        .catch((err) => {
          this.file3.status = "failed";
          this.file3.message = "上传失败";
        });
      console.log(this.fileList3);
    },
    async upload(file) {},

    deleteBgUrl3(file) {
      console.log(file);
      console.log(this.fileList3);
      this.liveForm.avatar = this.userInfo.avatar;
    },
    onChange(picker, value, index) {
      Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      Toast("取消");
    },
    async getMyInfoFunc() {
      this.userInfo = JSON.parse(getStore("userInfo"));
      console.log(this.userInfo);
      this.liveForm.nickname = this.userInfo.nickname;
      this.liveForm.avatar = this.userInfo.avatar;
      // this.fileList3.push({ url: this.userInfo.avatar });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initWXJSSDKNoShare()
    // 获取考前须知
    this.getMyInfoFunc();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
// @import url(); 引入公共css类
/* $safeHeight: calc(100vh - 60px); */
@import "~@/styles/variable.scss";
.container.ignore {
  /* height: $safeHeight;
  height: $safeHeight - constant(safe-area-inset-bottom);
  height: $safeHeight - env(safe-area-inset-bottom); */
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0%;
  z-index: 10;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #2298f4;
  .logo {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    img {
      height: 112px;
      z-index: 1;
    }
    .back {
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 99;
      padding: 20px;
      img {
        height: 45px;
        font-size: 32px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
      }
      span:last-child {
        margin-left: 20px;
      }
    }
  }
}
.btn_class {
  width: 690px;
  display: flex;
  border-radius: 105px 105px 105px 105px;
  overflow: hidden;
  .btn {
    // background-color: $baseColor;
    background: #2499f4;
    border: none;
    width: 100%;
    height: 100px;
  }
  .van-button--plain.van-button--primary {
    color: #ffffff;
    font-size: 30px;
    height: 88px;
    font-family: PingFang SC;
    font-weight: 400;
    border-radius: 4px;
  }
}
.user_header {
  width: 690px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .head_img {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .edit_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.39);
      overflow: hidden;
      .label {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;
        font-size: 28px;
        color: #fff;
      }
    }
  }
  .user_info {
    width: 690px;
    height: 88px;
    background-color: #f7f7f7;
    /* flex: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 65px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    .title,
    .input_title {
      width: 100%;
      line-height: 88px;
      font-size: 32px;
      letter-spacing: 2px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #333333;
      // background-color: #123abc;
      // vertical-align: middle;
    }
    .input_title {
      border: none;
      padding: 0;
      background: transparent;
      // background-color: #123abc;

      text-align: center;
    }

    img {
      // background-color: #123abc;

      width: 30px;
      height: 30px;
      margin-left: 30px;
      margin-right: -60px;
    }
  }
  .half_width {
    width: 450px !important;
  }
  .half_width_sm {
    flex: 1;
    .van-button--plain.van-button--primary {
      font-size: 28px;
      height: 100%;
    }
  }
  .welcome {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
  }
  .sub_welcome {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
  .tags_list {
    width: 690px;
    // min-height: calc(100vh - 806px);
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .tag_item {
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 0 20px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 30px 0px rgba(225, 232, 238, 0.5);
      border-radius: 4px;
      @include display(row, center, center);
      color: #333330;
      font-size: 26px;
    }
    &:last-child {
      // margin-bottom: 0;
    }
    .tag_item.is_active {
      background: #6a83fd;
      box-shadow: 0px 14px 20px 0px rgba(173, 187, 255, 0.43);
      border-radius: 4px;
      color: #ffffff;
    }
  }
  .setting {
    width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 36px;
      height: 34px;
    }
  }
}

.exam_box {
  margin-top: 120px;
  width: 100vw;
  height: calc(100% - 120px);

  .exam_content {
    /* box-sizing: border-box; */
    padding: 30px;
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 30px 30px 0 0;
    /* border: 12px solid #dae5ff; */
    box-shadow: 0px 0px 30px 0px rgba(225, 232, 238, 0.5);
  }
}
.apply_box,
.exam_box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // // top: 313px;
  // width: 690px;
  // min-height: 272px;
  // background: #dae5ff;
  // border-radius: 38px;
  .title_bar {
    position: absolute;
    top: 0;
    width: 374px;
    height: 67px;
  }
  .apply_content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 667px;
    height: 245px;
    background: #ffffff;
    border-radius: 27px;
  }
}
.first_title {
  box-sizing: border-box;
  position: absolute;
  margin: 55px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 54px;
  width: 690px;
  height: 85px;
  /* margin: 30px; */
  background: linear-gradient(90deg, #ffeccd 0%, #d5bb99 100%), #ffeccd;

  /* background: linear-gradient(180deg, #ffb953 0%, #ff823c 100%); */
  border-radius: 10px;
  color: #fff;
  font-size: 34px;
  .sign_in {
    margin: 0 47px;
  }
  img {
    width: 51px;
    height: 22px;
  }
}
.line_bar {
  position: absolute;
  width: 601px;
  height: 146px;
  top: 532px;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.form_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 26px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.field_item {
  position: relative;
  width: 100%;
  // height: 200px;
  padding-top: 40px;
  &.half_width {
    width: 50%;
    display: inline-block;
  }
  // background: linear-gradient(180deg, #f2f6f9 0%, #ffffff 100%);
  &.flex_column {
    ::v-deep {
      .van-field__control {
        display: flex;
        flex-direction: column;
        font-size: 28px;
      }
    }
  }
  .title {
    // margin-left: 40px;
    height: 37px;
    font-size: 26px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    color: #000;
    // line-height: 40px;
  }
  .van-cell.van-field {
    padding: 15px 0;
    background: transparent;
    border-bottom: 1px solid #979797;
    &.no_bottom_border {
      border-bottom: 1px solid #fff;
    }
    ::v-deep {
      .van-field__control {
        font-size: 28px;
      }
    }
  }
  .van-cell::after {
    border-bottom: none;
  }
  .upload_box {
    border-bottom: 1px solid #ffffff;
    &.twoImg {
      .van-cell.van-field {
        ::v-deep .van-uploader__wrapper {
          width: 200px;
          height: 200px;
        }
      }
    }
    .van-cell.van-field {
      border-bottom: none;
      ::v-deep .van-uploader__wrapper {
        width: 276px;
        height: 156px;
        .van-uploader__upload {
          width: 100%;
          height: 100%;
          margin: 0;
        }
        .van-uploader__preview {
          width: 100%;
          height: 100%;
          margin: 0;
          .van-uploader__preview-image {
            width: 100%;
            height: 100%;
            margin: 0;
          }
          // .van-uploader__preview-delete {
          //   width: 100%;
          //   height: 100%;
          //   background-color: transparent;
          // }
        }
      }
    }
    .tips {
      margin-bottom: 20px;
      margin-top: -20px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b1b8c6;
      line-height: 40px;
    }
  }
  .description {
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
  }
  .btn {
    width: 690px;
    height: 76px;
    background: #6a83fd;
    border-radius: 10px;
    color: #ffffff;
  }
  .add_btn {
    ::v-deep {
      font-size: 28px;
    }
  }
}
.cricle_icon {
  position: absolute;
  left: 4px;
  margin-top: 8px;
  width: 24px;
  height: 24px;
}
.van-radio {
  ::v-deep {
    .van-radio__icon {
      padding-top: 6px;
    }
  }
}
.radio_icon {
  width: 30px;
  height: 30px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  .radio_icon_inner {
    width: 16px;
    height: 16px;
    background: #e9e9e9;
  }
  &.checked {
    background: #d0d8eb;

    .radio_icon_inner {
      border-radius: 15px;
      width: 15px;
      height: 15px;
      background: #2148a0;
    }
  }
}
.arrow_down {
  position: absolute;
  bottom: 27px;
  right: 0;
  width: 24px;
  height: 24px;
}
</style>
<style lang="scss">
.edit_img {
  .van-uploader {
    width: 100%;
    height: 100%;
    .van-uploader__wrapper {
      width: 100%;
      height: 100%;
      .van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: transparent;
        .van-uploader__upload-icon,
        .van-uploader__upload-text {
          color: #fff;
        }
      }
      .van-uploader__preview {
        width: 100%;
        height: 100%;
        margin: 0;
        .van-uploader__preview-image {
          width: 100%;
          height: 100%;
          margin: 0;
        }
        .van-uploader__preview-delete {
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
